import React, { useState } from 'react'

import 'twin.macro'
import { GatsbyImage } from 'gatsby-plugin-image'
import moment from 'moment/moment'
import tw from 'twin.macro'

import { WebsiteButton as Button } from 'mx/components/section-components'
import { ArticleItem } from 'pages/blog'
import { sanitizeUrl } from 'views/utils'

type ContractorsProps = {
  items: ArticleItem[]
}
const ArticleSnippet = ({ items }: ContractorsProps) => {
  const itemsPerPage = 6
  const lastPage = (items.length || 0) / itemsPerPage
  const [page, setPage] = useState(1)

  const loadMore = () => {
    setPage(page + 1)
  }
  return (
    <div tw="w-full max-w-screen-xl h-full max-h-full px-4 mb-20 lg:mb-32 mt-10 lg:mt-14 mx-auto">
      {items.length > 0 && (
        <>
          <div tw="grid grid-cols-1 gap-x-16 lg:gap-x-24 w-full  md:grid-cols-2 h-max justify-items-center">
            {items.slice(0, itemsPerPage * page).map((item) => (
              <div
                key={item.title}
                tw="max-w-xl flex-col items-center bg-gray-light inline-flex my-6 md:my-12"
              >
                <div tw="relative w-full h-72 lg:h-96">
                  <GatsbyImage
                    css={tw`absolute inset-0 w-full h-full`}
                    imgStyle={{
                      objectFit: 'cover',
                      objectPosition: 'center',
                    }}
                    title={item.image?.title || ''}
                    alt={item.image?.description || ''}
                    image={
                      item.image?.localFile?.childImageSharp?.gatsbyImageData
                    }
                  />
                </div>

                <div tw="self-stretch p-9 md:p-20 lg:p-24 flex flex-col gap-9">
                  <div tw="flex justify-between items-center py-3 max-w-sm">
                    <p tw="text-brown-medium text-base font-light leading-relaxed">
                      {item.category}
                    </p>
                    <p tw="text-brown-medium text-base font-normal leading-relaxed capitalize">
                      {moment(item.publishedDate).format('MMM D, YYYY')}
                    </p>
                  </div>

                  <h2 tw="text-gray-dark text-2xl font-thin leading-tight">
                    {item.title}
                  </h2>

                  <div
                    tw="text-base text-gray-dark leading-normal font-light overflow-hidden text-ellipsis"
                    css={{
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 3,
                    }}
                    dangerouslySetInnerHTML={{
                      __html: item.introduction ?? '',
                    }}
                  />

                  <Button
                    color="transparentBlack"
                    rounded
                    onClick={() =>
                      window.open(
                        `/blog/article/${
                          item.publishingUrl || sanitizeUrl(item.title)
                        }`,
                      )
                    }
                  >
                    Read Post
                  </Button>
                </div>
              </div>
            ))}
          </div>

          {page < lastPage && (
            <div tw="flex justify-start my-6">
              <Button color="solidDark" rounded onClick={loadMore}>
                View More
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  )
}
export default ArticleSnippet
