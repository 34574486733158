import React from 'react'

import 'twin.macro'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import moment from 'moment'
import tw, { css } from 'twin.macro'

import { WebsiteButton as Button } from 'mx/components/section-components'
import { sanitizeUrl } from 'views/utils'

const Hero = (props: {
  author: {
    firstName: string
    lastName: string
  }
  category: string
  image: {
    title?: string
    description: string
    localFile: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData
      }
    }
  }
  introduction: string
  title: string
  publishedDate: string
  publishingUrl?: string
}) => {
  return (
    <div tw="bg-brown-medium text-white">
      <div tw="flex flex-col lg:flex-row max-md:flex-col-reverse w-full">
        <div tw="flex flex-col justify-center gap-10 px-12 py-20 sm:px-32 sm:py-20 lg:w-1/2 lg:py-15 lg:px-20 xl:px-32 text-balance max-sm:min-h-[400px] md:min-h-[600px] lg:min-h-fit">
          <div tw="flex items-center gap-14 py-3 md:py-6 max-w-sm">
            <p tw="text-white text-base font-light leading-relaxed">
              {props.category}
            </p>
            <p tw="text-white text-base font-light leading-relaxed capitalize">
              {moment(props.publishedDate).format('MMMM D, YYYY')}
            </p>
          </div>

          <h1 tw="text-3xl font-light md:text-4xl md:max-w-xl">
            {props.title}
          </h1>
          <p
            tw="text-base md:text-lg font-light md:max-w-xl"
            dangerouslySetInnerHTML={{
              __html: props.introduction ?? '',
            }}
          />

          <Button
            color="solidGray"
            rounded
            onClick={() =>
              window.open(
                `/blog/article/${
                  props.publishingUrl || sanitizeUrl(props.title)
                }`,
              )
            }
          >
            Read Post
          </Button>
        </div>

        <div tw="flex items-center justify-center w-full lg:w-1/2">
          <div
            tw="relative w-full h-full"
            css={css`
              &:before {
                content: '';
                display: block;
                padding-bottom: 100%;
              }
            `}
          >
            <GatsbyImage
              css={tw`absolute inset-0 w-full h-full`}
              imgStyle={{
                objectFit: 'cover',
                objectPosition: 'center',
              }}
              title={props.image.title || ''}
              alt={props.image.description || ''}
              image={props.image.localFile?.childImageSharp?.gatsbyImageData}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
